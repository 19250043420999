import copy from 'copy-to-clipboard';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FontSizes, TextButton } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { setBannerMessage } from '@/store/actions';

import messages from '../messages';
import { CopyWrapper, SsoSignupLinkLabel } from '../styles';

function SsoSignupUrl({ webRenewUrl }: { webRenewUrl?: string }): ReactElement | null {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();

	function copySignupUrl(): void {
		logEvent('Get Set Up : Copy Sign Up Link', { type: 'SSO' });
		if (webRenewUrl) {
			copy(webRenewUrl, {
				format: 'text/plain',
				onCopy: () => {
					dispatch(
						setBannerMessage({
							message: 'Signup Link copied to clipboard',
							isError: false,
							flash: true,
						}),
					);
				},
			});
		}
	}
	if (!webRenewUrl) {
		return null;
	}

	return (
		<>
			<SsoSignupLinkLabel>{formatMessage(messages.ssoSignupUrl)}</SsoSignupLinkLabel>
			<CopyWrapper>
				{webRenewUrl}
				<TextButton
					onClick={copySignupUrl}
					size={FontSizes.sm}
					hideUnderline
					data-appcues="copy-subscribe-url"
				>
					Copy
				</TextButton>
			</CopyWrapper>
		</>
	);
}

export default SsoSignupUrl;
