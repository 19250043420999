import { ReactElement, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import { SecondaryButton } from '@calm-web/design-system';

import { CARD_IMAGES } from '@/components/ui/Stripe/CreditCardIcons';
import { CalmError } from '@/utils/apiRequest/errors';

import { CardInfo } from '../types';
import messages from './messages';
import { CardDetail, CardDetailsContainer, DetailsContainer, Icon, Subtitle } from './styles';

interface Props {
	cardInfo: CardInfo;
	cardError: CalmError | undefined;
	setShowChangeModal: (showChangeModal: SetStateAction<boolean>) => void;
}

export default function PaymentDetails({ cardInfo, cardError, setShowChangeModal }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const cardImageDetails = CARD_IMAGES[cardInfo.brand as keyof typeof CARD_IMAGES];
	return (
		<DetailsContainer data-testid="payment-details">
			{cardError && <Subtitle>Error: {cardError.data.error.code}</Subtitle>}
			{!cardError && cardInfo && (
				<>
					<Subtitle>
						{formatMessage(messages.cardName, {
							cardname: cardInfo.brand,
						})}
						{cardImageDetails && <Icon src={cardImageDetails.src} alt={cardImageDetails.alt} />}
					</Subtitle>
					<Subtitle>
						{formatMessage(messages.cardDetails, {
							cardnumber: cardInfo.last4,
						})}
					</Subtitle>
					<CardDetailsContainer>
						<CardDetail>
							{formatMessage(messages.cardExp, {
								cardexp: `${cardInfo.exp_month}/${cardInfo.exp_year}`,
							})}
						</CardDetail>
					</CardDetailsContainer>
				</>
			)}

			<SecondaryButton data-testid="change-card-button" onPress={(): void => setShowChangeModal(true)}>
				{formatMessage(messages.changeDetailsLink)}
			</SecondaryButton>
		</DetailsContainer>
	);
}
