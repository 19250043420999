import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { setBannerMessage } from '@/store/actions';
import { Invoices } from '@/types/invoices';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';

import { ApiResponse } from './types';
import { useApi } from './useApi';

export function useInvoices(partnerId: string): ApiResponse<Invoices[]> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const { data, isLoading, error } = useSWR(
		`b2b/partners/${partnerId}/invoices`,
		async endpoint => {
			try {
				const response = await apiRequest({ endpoint });
				return response.data.invoices;
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: `Failed to retrieve stripe invoices`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
		{ errorRetryCount: 0 },
	);

	return {
		data,
		error,
		loading: isLoading,
	};
}

export function updateInvoicesCache(partnerId: string): Promise<boolean | undefined> {
	return mutate(`b2b/partners/${partnerId}/invoices`);
}

type RefundFunction = () => Promise<void>;
export function useRefundInvoice(
	partnerId: string,
	invoiceId: string | null,
): [RefundFunction, ApiResponse<boolean>] {
	const apiRequest = useApi();
	const [result, setResult] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<CalmError | Error | undefined>();

	const refundInvoices = async (): Promise<void> => {
		setError(undefined);

		if (loading) return;
		try {
			setLoading(true);

			const url = `b2b/partners/${partnerId}/invoice/${invoiceId}/refund`;
			const response = await apiRequest({
				endpoint: url,
				method: 'POST',
			});
			const { data } = response;

			setResult(data);
			await updateInvoicesCache(partnerId);
			return data;
		} catch (err) {
			const parsed = getCalmErrorOrError(err);
			setError(parsed);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	// Clear out any errors if a component is removed
	useEffect(() => {
		return () => {
			setError(undefined);
		};
	}, [setError]);

	return [refundInvoices, { data: result, loading, error }];
}
