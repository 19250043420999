import { FC, ReactElement, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router-dom';
import { Column } from 'react-table';

import { Loader, FormInput } from '@calm-web/design-system';
import { OnChange } from '@calm-web/use-form';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import CellContainer from '@/components/ui/CellContainer';
import MobilePaginator from '@/components/ui/MobilePaginator';
import Table from '@/components/ui/Table';
import { useDownloadEligibilityCsv, useEligibility } from '@/hooks/api/useEligibility';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { usePartner } from '@/hooks/api/usePartner';
import { Stats, usePartnerStats } from '@/hooks/api/usePartnerStats';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { Eligibility } from '@/types/eligibility';
import { IntegrationType, Partner } from '@/types/store/reducers';
import { ApiError } from '@/utils/apiRequest/errors';

import Search from 'icons/user-search.svg';

import messages from './messages';
import {
	DownloadButton,
	HeaderContainer,
	HeaderRow,
	LoadingContainer,
	SearchRow,
	SearchWrapper,
	TableContainer,
	AccessCodesTableCard,
	CounterText,
	MobileUsersWrapper,
	MobileUsersRow,
	MobileDataColumn,
	MobileLabel,
} from './styles';

interface ContentProps {
	partner: Partner;
}

const PAGE_SIZE = 10;

function downloadButtonText(formatMessage: IntlShape['formatMessage'], error: unknown): string {
	if (error) {
		return formatMessage(messages.downloadButtonTextError);
	}

	return formatMessage(messages.downloadButtonText);
}

function DownloadAccessCodesButton({ partnerId }: { partnerId: string }): ReactElement {
	const { formatMessage } = useIntl();
	const [downloadEligibilityCsv, { loading, error }] = useDownloadEligibilityCsv(partnerId);

	return (
		<DownloadButton
			onPress={(): Promise<void> => downloadEligibilityCsv()}
			isLoading={loading}
			isDisabled={loading}
		>
			{downloadButtonText(formatMessage, error)}
		</DownloadButton>
	);
}

function CreatedCell({ value }: { value: string }): ReactElement {
	const formattedDate = new Date(value).toLocaleString();
	return <>{formattedDate.toLowerCase()}</>;
}

function StatsError(): ReactElement {
	const { formatMessage } = useIntl();
	return <>{formatMessage(messages.statsLoadingError)}</>;
}

function CounterContainer({
	statsLoading,
	statsError,
	stats,
}: {
	statsLoading: boolean;
	statsError: Error | ApiError | undefined;
	stats: Stats | undefined;
}): ReactElement {
	if (statsLoading) {
		return (
			<LoadingContainer>
				<Loader color="gray1" />
			</LoadingContainer>
		);
	}
	if (statsError || !stats) {
		return <StatsError />;
	}
	const message =
		stats.numCoveredLives === 0 ? 'No access codes found.' : `${stats.numCoveredLives} access codes`;
	return <CounterText>{message}</CounterText>;
}

function AccessCodesContainer({
	searchValue,
	partnerId,
}: {
	searchValue: string;
	partnerId: string;
}): ReactElement {
	const { data: stats, loading: statsLoading, error: statsError } = usePartnerStats(partnerId, searchValue);

	return (
		<HeaderRow>
			<CounterContainer statsLoading={statsLoading} statsError={statsError} stats={stats} />
			<DownloadAccessCodesButton partnerId={partnerId} />
		</HeaderRow>
	);
}

const UserRow = ({ user }: { user: Eligibility }): ReactElement => (
	<MobileUsersRow>
		<MobileDataColumn>
			<MobileLabel>Unique Identifier</MobileLabel>
			<p>{user.partner_user_id}</p>
		</MobileDataColumn>
		<MobileDataColumn>
			<MobileLabel>Created At</MobileLabel>
			<CreatedCell value={user.created_at} />
		</MobileDataColumn>
	</MobileUsersRow>
);

function BodyContainer({
	partner,
	searchValue,
	pageIndex,
	setPageIndex,
}: {
	partner: Partner;
	searchValue: string;
	pageIndex: number;
	setPageIndex: (index: number) => void;
}): ReturnType<FC> {
	const [isMobile] = useIsMobile();
	const { formatMessage } = useIntl();
	const columns: Column<Eligibility>[] = useMemo(
		() => [
			{
				Header: 'Access codes',
				accessor: 'partner_user_id',
			},
			{ Header: 'Created', accessor: 'created_at', Cell: CreatedCell },
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[partner],
	);
	const { data: stats, loading: statsLoading, error: statsError } = usePartnerStats(partner.id, searchValue);
	const {
		loading,
		data: eligibilityData,
		error,
	} = useEligibility(partner.id, pageIndex, PAGE_SIZE, searchValue);

	const users = eligibilityData?.eligibilities || [];
	const pageCount = stats ? Math.max(1, Math.ceil(stats.numCoveredLives / PAGE_SIZE)) : 0;

	if (statsError) {
		return null;
	}

	return (
		<>
			<CellContainer>
				<AccessCodesTableCard>
					{isMobile ? (
						<>
							<MobileUsersWrapper>
								{users.map(user => (
									<UserRow key={user.id} user={user} />
								))}
							</MobileUsersWrapper>
							<MobilePaginator
								fetchData={({ pageIndex: newPageIndex }: { pageIndex: number }): void =>
									setPageIndex(newPageIndex)
								}
								loading={loading || statsLoading}
								pageCount={pageCount}
								error={error && formatMessage(messages.eligibleUsersErrorMessage)}
								pageSize={PAGE_SIZE}
							/>
						</>
					) : (
						<Table
							columns={columns}
							data={users}
							fetchData={({ pageIndex: newPageIndex }: { pageIndex: number }): void =>
								setPageIndex(newPageIndex)
							}
							loading={loading || statsLoading}
							pageCount={pageCount}
							error={error && formatMessage(messages.eligibleUsersErrorMessage)}
							pageSize={PAGE_SIZE}
							forcePageIndex={pageIndex}
						/>
					)}
				</AccessCodesTableCard>
			</CellContainer>
		</>
	);
}

function Content({ partner }: ContentProps): ReactElement {
	const { formatMessage } = useIntl();

	const [searchValue, setSearchValue] = useState('');
	const [pageIndex, setPageIndex] = useState(0);
	const onSearchChange: OnChange = e => {
		setSearchValue(e.target.value);
		setPageIndex(0);
	};

	return (
		<TableContainer>
			<HeaderContainer>
				<AccessCodesContainer searchValue={searchValue} partnerId={partner.id} />
				<SearchRow>
					<SearchWrapper>
						<FormInput
							label={formatMessage(messages.searchPlaceholder)}
							onChange={onSearchChange}
							name="search"
							value={searchValue}
							Icon={Search}
							noValidation
						/>
					</SearchWrapper>
				</SearchRow>
			</HeaderContainer>
			<BodyContainer
				partner={partner}
				searchValue={searchValue}
				pageIndex={pageIndex}
				setPageIndex={setPageIndex}
			/>
		</TableContainer>
	);
}

export default function AccessCodes(): ReturnType<FC> {
	const { partnerId } = useParams();
	const { data: partner } = usePartner(partnerId);
	const { data: flagValuesForEligibility } = useFeatureFlags('b2b-block-eligibility-endpoint');
	const blockEligibilityEndpoint =
		flagValuesForEligibility && flagValuesForEligibility['b2b-block-eligibility-endpoint'];
	const { formatMessage } = useIntl();

	if (!partner) {
		return null;
	}

	if (partner.integration_type !== IntegrationType.ACCESS_CODES) {
		return <Redirect to={`/${partnerId}/users`} />;
	}

	if (blockEligibilityEndpoint) {
		return <div>{formatMessage(messages.eligibilityBlockedErrorMessage)}</div>;
	}

	return (
		<>
			<InitialPageAnalytics />
			<Content partner={partner} />
		</>
	);
}
