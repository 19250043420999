import omit from 'lodash/omit';
import Image from 'next/legacy/image';
import { ReactNode, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { fileFromModelValue, stringFromModelValue, OnChange } from '@calm-web/use-form';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useRequestLogoAccess } from '@/hooks/api/usePartner';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { useShouldShowLogoBrandingText } from '@/hooks/useShouldShowLogoBrandingText';
import { setBannerMessage } from '@/store/actions';
import { Partner } from '@/types/store/reducers';
import { srcLoader } from '@/utils/ui/imageLoader';
import { createUrl } from '@/utils/url';

import messages from './messages';
import {
	Container,
	DefaultImg,
	DeleteButton,
	FlexContainer,
	FormContainer,
	ImageContainer,
	LogoText,
	Title,
	Subtitle,
	UploadButton,
	UploadText,
	RequestAccessButton,
	SubscribeLink,
} from './styles';

const LogoUploader: React.FC<{
	formProps: EditPartnerFormProps;
	disabled?: boolean;
	asRequestAccess?: boolean;
	partner: Partner;
	fileFieldName: keyof EditPartnerFormProps['model'];
	urlFieldName: keyof EditPartnerFormProps['model'];
}> = ({ formProps, disabled = false, asRequestAccess = false, partner, fileFieldName, urlFieldName }) => {
	const { formatMessage } = useIntl();
	const inputRef = useRef<HTMLInputElement>(null);
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const [requestLogoAccess, requestLogoAccessResponse] = useRequestLogoAccess(partner.id);

	const imgIsLargerThan = (file: File, megaBytes: number): boolean => {
		const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
		return parseFloat(filesize) > megaBytes;
	};
	const fileInputProps = formProps.bindWithErrorProps(fileFieldName, 'file');
	const imageChange: OnChange = async e => {
		const target = e.target;
		if (target.files && target.files.length > 0) {
			const file = target.files[0];
			if (imgIsLargerThan(file, 2)) {
				dispatch(
					setBannerMessage({
						message: 'Image is too large. Please upload a file under 2MB.',
						flash: true,
						isError: true,
					}),
				);
			} else {
				fileInputProps.onChange(e);
				logEvent('Partner : Logo Upload : Selected');
			}
		} else {
			formProps.setProperty(urlFieldName, '');
			formProps.setProperty(fileFieldName, []);
		}
	};

	const uploadButtonClick = (): void => {
		if (inputRef && inputRef.current) {
			inputRef.current.click();
		}
	};

	const deleteButtonClick = (): void => {
		formProps.setProperty(urlFieldName, '');
		formProps.setProperty(fileFieldName, []);
		if (inputRef.current) {
			inputRef.current.value = '';
		}
	};

	const logoImgFile = fileFromModelValue(formProps.model[fileFieldName]);
	const logoImg = createUrl(logoImgFile);
	const backupImg = stringFromModelValue(formProps.model[urlFieldName]);
	const imgSrc = logoImg || backupImg;
	const requestAccessDidSucceed = Boolean(requestLogoAccessResponse.data);
	const isDtcLogo = urlFieldName === 'dtcLogoUrl';
	const imageAcceptFormat = isDtcLogo ? 'image/jpeg' : 'image/png, image/svg+xml';

	const shouldShowSubscribeText = useShouldShowLogoBrandingText(partner.id);
	const getPartnerBrandingSubtitle = (): ReactNode => {
		const b2bLogoText = shouldShowSubscribeText
			? formatMessage(messages.partnerBrandingSubtitleWithSubscribeText, {
					subscribe: (...chunks: ReactNode[]) => (
						<SubscribeLink href={partner.redemption_url} target="_blank" rel="noopener noreferrer">
							{chunks}
						</SubscribeLink>
					),
			  })
			: formatMessage(messages.partnerBrandingSubtitle);
		return !isDtcLogo ? b2bLogoText : formatMessage(messages.partnerDtcLogoSubtitle);
	};

	return (
		<Container>
			<Title htmlFor={fileInputProps.name}>
				{!isDtcLogo
					? formatMessage(messages.partnerBrandingTitle)
					: formatMessage(messages.partnerDtcLogoTitle)}
			</Title>
			<Subtitle>{getPartnerBrandingSubtitle()}</Subtitle>
			<FlexContainer>
				{imgSrc ? (
					<ImageContainer $disabled={disabled} onClick={uploadButtonClick} role="button">
						<Image src={imgSrc} alt="Your Logo" layout="fill" objectFit="contain" loader={srcLoader} />
					</ImageContainer>
				) : (
					<DefaultImg
						$disabled={disabled}
						$background={!isDtcLogo ? 'gray1' : 'gradientSceneBlueGold'}
						onClick={uploadButtonClick}
						role="button"
					>
						<LogoText color={!isDtcLogo ? 'gray6' : 'white'}>
							{formatMessage(messages.partnerBrandingLogo)}
						</LogoText>
					</DefaultImg>
				)}
				{!disabled && (
					<FormContainer>
						{asRequestAccess ? null : (
							<input
								{...omit(fileInputProps, ['isValid', 'showValidation'])}
								id={fileInputProps.name}
								onChange={imageChange}
								ref={inputRef}
								style={{ display: 'none' }}
								accept={imageAcceptFormat}
								data-testid={`file-input-${fileInputProps.name}`}
							/>
						)}
						{asRequestAccess ? (
							<RequestAccessButton
								onClick={requestLogoAccess}
								isLoading={requestLogoAccessResponse.loading}
								textColor={requestAccessDidSucceed ? 'success' : 'blue3'}
							>
								{requestAccessDidSucceed ? 'Request Sent!' : imgSrc ? 'Change Logo' : 'Join Waitlist'}
							</RequestAccessButton>
						) : (
							<>
								<UploadButton onClick={uploadButtonClick}>
									{formatMessage(messages.uploadButton)}
								</UploadButton>
								<DeleteButton onClick={deleteButtonClick}>
									{formatMessage(messages.deleteButton)}
								</DeleteButton>
							</>
						)}
						<UploadText>
							{!isDtcLogo
								? formatMessage(messages.imageRequirements, { maxImageSizeMB: 2 })
								: formatMessage(messages.dtcImageRequirementsV1)}
						</UploadText>
					</FormContainer>
				)}
			</FlexContainer>
		</Container>
	);
};

export default LogoUploader;
