import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useForm, {
	FormProps,
	validation,
	stringFromModelValue,
	booleanFromCheckbox,
} from '@calm-web/use-form';

import { setBannerMessage } from '@/store/actions';
import { EligibilityValidator, HealthConfig } from '@/types/health';
import { IntegrationType } from '@/types/store/reducers';

import { usePermissions } from '../auth';
import {
	EditHealthEligibilityValidatorFormProps,
	useHealthEligibilityValidatorForms,
} from './useHealthEligibilityValidatorForm';

interface UseHealthConfigSubmitDataResponse {
	getHealthConfigSubmitData: () => Partial<HealthConfig>;
	showValidationErrors: () => boolean;
}

const getErrorMessage = (
	props: EditHealthConfigFormProps | EditHealthEligibilityValidatorFormProps,
): string | undefined => {
	const genericProps = props as FormProps<string>;
	return stringFromModelValue(
		Object.keys(genericProps.validation.fields)
			.map(fieldName => stringFromModelValue(genericProps.validation.fields[fieldName]?.errors))
			.filter(Boolean) as string[],
	);
};

export const useHealthConfigSubmitData = (
	baseFormProps: EditHealthConfigFormProps,
	eligibilityValidatorFormProps: EditHealthEligibilityValidatorFormProps[],
): UseHealthConfigSubmitDataResponse => {
	const dispatch = useDispatch();
	const [hasValidPermissions, actions] = usePermissions();

	const getHealthConfigSubmitData = useCallback((): Partial<HealthConfig> => {
		const shouldReturnEmail = ['email', 'both'].includes(baseFormProps.model.clientSupportChannel as string);
		const shouldReturnPhone = ['phone', 'both'].includes(baseFormProps.model.clientSupportChannel as string);

		const baseHealthConfigData: Partial<HealthConfig> = {
			landing_header: stringFromModelValue(baseFormProps.model.landingHeader),
			landing_body: stringFromModelValue(baseFormProps.model.landingBody),
			baa_data_retention:
				stringFromModelValue(baseFormProps.model.baaDataRetention) === 'true' ? true : false,
			enable_data_feed_override:
				stringFromModelValue(baseFormProps.model.enableDataFeedOverride) === 'true' ? true : false,
			hide_all_referrals: !booleanFromCheckbox(baseFormProps.model.show_referrals, 'show_referrals'),
			client_support_email: shouldReturnEmail
				? stringFromModelValue(baseFormProps.model.clientSupportEmail)
				: null,
			client_support_phone: shouldReturnPhone
				? stringFromModelValue(baseFormProps.model.clientSupportPhone)
				: null,
			integration_type: stringFromModelValue(baseFormProps.model.integration_type) as IntegrationType,
			client_id: stringFromModelValue(baseFormProps.model.client_id),
		};

		// Clear out the support details as they need to be removed and no longer reflected prior to submission
		if (!shouldReturnEmail) {
			baseFormProps.setProperty('clientSupportEmail', '');
		}
		if (!shouldReturnPhone) {
			baseFormProps.setProperty('clientSupportPhone', '');
		}

		Object.keys(baseHealthConfigData).forEach((partnerField: string) => {
			const updatedPartnerField = partnerField === 'client_id' ? 'health_client_id' : partnerField;
			if (!hasValidPermissions(updatedPartnerField, [actions.UPDATE])) {
				delete baseHealthConfigData[partnerField as keyof HealthConfig];
			}
		});

		const eligibilityValidatorData = eligibilityValidatorFormProps
			.map(props => {
				const baseEligibilityValidatorData = {
					validation_name: props.model.validation_name as string,
					validation_type: props.model.validation_type as 'text' | 'date' | 'email' | 'phone' | 'numeric',
					validation_display_name: props.model.validation_display_name as string,
					// Hard code this as true until it's user-configurable
					is_required_field_at_redemption: true,
				};
				Object.keys(baseEligibilityValidatorData).forEach((partnerField: string) => {
					if (!hasValidPermissions(`eligibility_validator_${partnerField}`, [actions.UPDATE])) {
						delete baseEligibilityValidatorData[partnerField as keyof typeof baseEligibilityValidatorData];
					}
				});
				return Object.keys(baseEligibilityValidatorData).length > 0
					? baseEligibilityValidatorData
					: undefined;
			})
			.filter(Boolean) as EligibilityValidator[];

		return {
			...baseHealthConfigData,
			...(eligibilityValidatorData.length > 0
				? { eligibility_validators: eligibilityValidatorData }
				: undefined),
		};
	}, [actions.UPDATE, baseFormProps, eligibilityValidatorFormProps, hasValidPermissions]);

	const showValidationErrors = useCallback((): boolean => {
		const allFormProps = [baseFormProps, ...eligibilityValidatorFormProps];
		if (allFormProps.every(props => props.validation.isValid)) {
			return false;
		}
		const errorMessage =
			stringFromModelValue(allFormProps.map(getErrorMessage).filter(Boolean) as string[]) ??
			'Please check you have filled out all required fields';
		dispatch(
			setBannerMessage({
				message: `Error: ${errorMessage}`,
				flash: true,
				isError: true,
			}),
		);
		return true;
	}, [dispatch, eligibilityValidatorFormProps, baseFormProps]);

	return { getHealthConfigSubmitData, showValidationErrors };
};

const useBaseHealthConfigForm = (
	healthConfig?: Pick<
		HealthConfig,
		| 'landing_header'
		| 'landing_body'
		| 'baa_data_retention'
		| 'enable_data_feed_override'
		| 'hide_all_referrals'
		| 'client_support_email'
		| 'client_support_phone'
		| 'integration_type'
		| 'client_id'
	>,
): {
	formProps: EditHealthConfigFormProps;
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
} => {
	const formProps: EditHealthConfigFormProps = useForm('healthConfigForm', {
		initialModel: {
			landingHeader: healthConfig?.landing_header ?? '',
			landingBody: healthConfig?.landing_body ?? '',
			baaDataRetention: healthConfig?.baa_data_retention ? 'true' : 'false',
			enableDataFeedOverride: healthConfig?.enable_data_feed_override ? 'true' : 'false',
			show_referrals: healthConfig?.hide_all_referrals ? [] : ['show_referrals'],
			clientSupportEmail: healthConfig?.client_support_email ?? '',
			clientSupportPhone: healthConfig?.client_support_phone ?? '',
			clientSupportChannel:
				healthConfig?.client_support_email && healthConfig?.client_support_phone
					? 'both'
					: healthConfig?.client_support_email
					? 'email'
					: 'phone',
			integration_type: healthConfig?.integration_type ?? IntegrationType.GROUP_CODE,
			client_id: healthConfig?.client_id ?? '',
		},
		validation: {
			landingHeader: validation.validateOrFail([
				{
					rules: [validation.maxLength(50)],
					errorResult: 'Please enter a header with a maximum of 50 characters',
				},
			]),
			landingBody: validation.validateOrFail([
				{
					rules: [validation.maxLength(250)],
					errorResult: 'Please enter a body with a maximum of 250 characters',
				},
			]),
			clientSupportEmail: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							const isEmailRequired = ['both', 'email'].includes(props.model.clientSupportChannel as string);
							if (isEmailRequired) {
								return validation.email(value);
							}
							return true;
						},
					],
					errorResult: 'Please enter a valid email address',
				},
			]),
			clientSupportPhone: validation.validateOrFail([
				{
					rules: [
						(value, props) => {
							const isPhoneRequired = ['both', 'phone'].includes(props.model.clientSupportChannel as string);
							if (isPhoneRequired) {
								return validation.phone(value);
							}
							return true;
						},
					],
					errorResult: 'Please enter a valid phone number',
				},
			]),
		},
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

export const useHealthConfigForm = (
	healthConfig?: Pick<
		HealthConfig,
		| 'landing_header'
		| 'landing_body'
		| 'baa_data_retention'
		| 'enable_data_feed_override'
		| 'hide_all_referrals'
		| 'client_support_email'
		| 'client_support_phone'
		| 'integration_type'
		| 'client_id'
		| 'eligibility_validators'
	>,
): {
	baseFormProps: EditHealthConfigFormProps;
	eligibilityValidatorFormProps: EditHealthEligibilityValidatorFormProps[];
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
	resetAllDirtyStates: () => void;
	addEligibilityValidator: () => void;
	removeEligibilityValidator: (index: number) => void;
} => {
	const baseForm = useBaseHealthConfigForm(healthConfig);
	const eligibilityValidatorForms = useHealthEligibilityValidatorForms(healthConfig);

	const hasChangedAny = baseForm.hasChangedAny || eligibilityValidatorForms.hasChangedAny;
	const hasTouchedAny = baseForm.hasTouchedAny || eligibilityValidatorForms.hasTouchedAny;

	const resetAllDirtyStates = useCallback((): void => {
		baseForm.formProps.resetAllDirtyStates();
		eligibilityValidatorForms.resetAllDirtyStates();
	}, [baseForm.formProps, eligibilityValidatorForms]);

	return {
		baseFormProps: baseForm.formProps,
		eligibilityValidatorFormProps: eligibilityValidatorForms.eligibilityValidatorFormProps,
		hasChangedAny,
		hasTouchedAny,
		resetAllDirtyStates,
		addEligibilityValidator: eligibilityValidatorForms.addEligibilityValidator,
		removeEligibilityValidator: eligibilityValidatorForms.removeEligibilityValidator,
	};
};

export type FieldNames =
	| 'landingHeader'
	| 'landingBody'
	| 'baaDataRetention'
	| 'enableDataFeedOverride'
	| 'show_referrals'
	| 'clientSupportEmail'
	| 'clientSupportPhone'
	| 'clientSupportChannel'
	| 'integration_type'
	| 'client_id';

export type EditHealthConfigFormProps = FormProps<FieldNames>;
