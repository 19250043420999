import { ReactElement, useEffect, forwardRef, Ref, useContext, useState, useRef, RefObject } from 'react';
import { useIntl } from 'react-intl';

import { ReportingContext } from '@/components/providers/ReportingContextProvider';
import { aggregationLevelToTimeUnit } from '@/hooks/api/reporting/utils';
import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { useHoverTracking } from '@/hooks/useHoverTracking';
import friendlyPercentage from '@/utils/friendlyPercentage';

import WidgetCard from '../../WidgetCard';
import SignupsOrEngagementGraph from '../Graph';
import RankingsTable from '../RankingsTable';
import messages from './messages';
import { PrimaryText } from './styles';

interface Props {
	isPrivacyError: boolean;
	isNoDataError: boolean;
	isShowingSegmented?: boolean;
}

function omitLastIncompleteAggregationLevel<T extends { date: string }>(data: T[]): T[] {
	const sorted = data.sort((a, b) => a.date.localeCompare(b.date));
	return sorted;
}

const RANKINGS_FF = 'b2b-top-five-segments';

function Signups(
	{ isPrivacyError, isNoDataError, isShowingSegmented }: Props,
	forwardedRef?: Ref<HTMLDivElement>,
): ReactElement {
	const { formatMessage } = useIntl();
	const { signupsAndEngagementReport, partner } = useContext(ReportingContext);
	const {
		covered_lives,
		sign_up_count,
		sign_up_rate,
		sign_up_count_at_start,
		sign_up_start_to_end_change_percentage,
		activity_report,
		aggregation_level,
		signups_benchmark,
	} = signupsAndEngagementReport?.data || {};

	const { signups } = activity_report || {};
	const eligibleCount = covered_lives;
	const [signupsPerAggregationLevelData, setSignupsPerAggregationLevelData] =
		useState<{ date: string; value: number }[]>();
	const [hasSignupData, setHasSignupData] = useState<boolean | undefined>();
	const [startToEndChange, setStartToEndChange] = useState<number | undefined>();
	const [startToEndLabel, setStartToEndLabel] = useState<string | undefined>();
	const benchmarkRef = useRef<HTMLDivElement>(null);
	const graphRef = useRef<HTMLDivElement>(null);
	const { data: flagValuesForRankings } = useFeatureFlags(RANKINGS_FF);
	const showRankingsTable =
		flagValuesForRankings && flagValuesForRankings[RANKINGS_FF] && partner?.integration_type !== 'SSO';

	useHoverTracking({
		loggingPrefix: 'Reporting : Signups',
		containerRef: forwardedRef as RefObject<HTMLDivElement>,
		targetRefs: [
			{ name: 'graph', ref: graphRef },
			{ name: 'benchmark', ref: benchmarkRef },
		],
	});

	useEffect(() => {
		if (signups) {
			const signupsPerAggregationLevel = omitLastIncompleteAggregationLevel(
				signups.map(datum => ({
					date: datum.starting_ts,
					value: datum.signup_count,
				})),
			);
			setSignupsPerAggregationLevelData(signupsPerAggregationLevel);
			setHasSignupData(
				signupsPerAggregationLevel?.length > 1 && signupsPerAggregationLevel?.some(s => s.value !== 0),
			);
			setStartToEndChange(sign_up_start_to_end_change_percentage);
			if (signups && aggregation_level) {
				setStartToEndLabel(`${signups.length} ${aggregationLevelToTimeUnit[aggregation_level]} change`);
			}
		}
	}, [
		signups,
		aggregation_level,
		sign_up_count,
		sign_up_count_at_start,
		sign_up_start_to_end_change_percentage,
	]);

	const primaryText = (
		<PrimaryText>
			{formatMessage(messages.signupsBody, {
				signupCount: sign_up_count,
				eligibleCount,
			})}
		</PrimaryText>
	);

	return (
		<WidgetCard
			isLoading={signupsAndEngagementReport?.loading}
			isPrivacyError={isPrivacyError}
			isNoDataError={isNoDataError}
			ref={forwardedRef}
			heading={formatMessage(messages.signupsEyebrow)}
			subHeading="Track how many members have activated their Calm Premium benefit."
			primaryPercentage={friendlyPercentage(sign_up_rate)}
			{...(typeof startToEndChange !== 'undefined'
				? {
						secondaryText: startToEndLabel,
						secondaryPercentage: friendlyPercentage(startToEndChange, true),
				  }
				: {})}
			primaryText={primaryText}
			benchmark={signups_benchmark}
			benchmarkRef={benchmarkRef}
			content={
				signupsAndEngagementReport?.data && signupsPerAggregationLevelData ? (
					<>
						<SignupsOrEngagementGraph
							forwardedRef={graphRef}
							aggregationLevel={signupsAndEngagementReport.data.aggregation_level}
							data={signupsPerAggregationLevelData}
							tooltipLabel={formatMessage(messages.signupGraphTooltipLabel)}
							paddingSide="left"
							hasNoSignups={!hasSignupData}
						/>
						{!isShowingSegmented && showRankingsTable && <RankingsTable />}
					</>
				) : undefined
			}
			resourceDescription={formatMessage(messages.signupsResourceDescription)}
			resourceData={[
				{
					title: 'Calm Sign-up Guide',
					link: 'https://docs.google.com/document/d/1sO-w2LM-Q6MGZ3Gozh4OiQuuCACdRYG6VZx29KlgEyk/edit',
					openInNewTab: true,
				},
				{
					title: 'Calm Enrollment Resources',
					link: 'https://info.calm.com/rs/541-LYF-023/images/Calm_Enrollment_Resources.pdf',
					openInNewTab: true,
				},
			]}
		/>
	);
}

export default forwardRef(Signups);
