import { useFeatureFlags } from './api/useFeatureFlags';

const B2B_PARTNER_LOGO_ON_SUBSCRIBE_PAGE = 'b2b-partner-logo-on-subscribe-page';

export const useShouldShowLogoBrandingText = (partnerId: string): boolean | undefined => {
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(B2B_PARTNER_LOGO_ON_SUBSCRIBE_PAGE);
	const flagsLoaded = !flagLoading && !flagError && flagValues;
	const logoFlag =
		flagValues &&
		(flagValues[B2B_PARTNER_LOGO_ON_SUBSCRIBE_PAGE] as
			| {
					isEnabled: boolean;
					allowedPartnerIds: string[];
			  }
			| undefined);
	const shouldShowLogoBrandingText =
		flagsLoaded && (logoFlag?.isEnabled || logoFlag?.allowedPartnerIds.includes(partnerId));

	return shouldShowLogoBrandingText;
};
