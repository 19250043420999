import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import { usePartner } from '@/hooks/api/usePartner';
import { isSelfServePlan } from '@/utils/SkuUtils';

import StripeElements from '../Teams/StripeElements';
import InvoiceHistory from './InvoiceHistory';
import PlanDetails from './PlanDetails';
import messages from './PlanDetails/messages';
import { PlanContainer } from './styles';

export default function Plan(): ReactElement {
	const { partnerId } = useParams();
	const { data: partner } = usePartner(partnerId);
	const { formatMessage } = useIntl();

	return (
		<StripeElements mode="setup">
			<PlanContainer>
				<InitialPageAnalytics />
				{partner && isSelfServePlan(partner?.vouched_plan_sku) ? (
					<>
						<PlanDetails partner={partner} />

						<InvoiceHistory partner={partner} />
					</>
				) : (
					<div>
						{formatMessage(messages.notSelfServe, {
							partnerName: partner?.name,
						})}
					</div>
				)}
			</PlanContainer>
		</StripeElements>
	);
}
