import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FontSizes, TextButton } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { createB2BPartnerSubscribeUrlPreview } from '@/utils/url';

import messages from '../messages';
import { CopyWrapper, SubscribeLinkExplainer, SubscribeLinkLabel, SubscribeLinkPreview } from '../styles';
import { copySubscribeUrl } from './utils';

function SubscribeUrl({
	skuType,
	partnerSlug,
	isFinalized,
}: {
	skuType: string;
	partnerSlug: string;
	isFinalized: boolean;
}): ReactElement {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const subscribeUrl = createB2BPartnerSubscribeUrlPreview({ slug: partnerSlug, skuType });

	const copyUrl = async (): Promise<void> => {
		logEvent('Account : Subscribe Link Copied', { triggeredFrom: 'Account Page : Account Details' });
		await copySubscribeUrl({ subscribeUrl, dispatch, navigator, document });
	};

	if (isFinalized) {
		return (
			<>
				<SubscribeLinkLabel>{formatMessage(messages.subscribeUrl)}</SubscribeLinkLabel>
				<CopyWrapper>
					{subscribeUrl}
					<TextButton onClick={copyUrl} size={FontSizes.sm} hideUnderline data-appcues="copy-subscribe-url">
						Copy
					</TextButton>
				</CopyWrapper>
			</>
		);
	}

	return (
		<>
			<SubscribeLinkLabel>{formatMessage(messages.subscribeUrlPreview)}</SubscribeLinkLabel>
			<SubscribeLinkExplainer>{formatMessage(messages.subscribeUrlExplainer)}</SubscribeLinkExplainer>
			<SubscribeLinkPreview>{subscribeUrl}</SubscribeLinkPreview>
		</>
	);
}

export default SubscribeUrl;
