import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import {
	fontWeight,
	FontWeights,
	FontSizes,
	TextButton,
	lineHeight,
	units,
	Text,
	TextElements,
	CalmTheme,
	fontSize,
} from '@calm-web/design-system';

const imgHeight = '60px';
const imgWidth = '100px';

export const Container = styled.div`
	&:not(:last-child) {
		margin-bottom: ${units(2)};
	}
	background-color: ${palette('gray0')};
	border-radius: 5px;
	padding: ${units(2)};
`;

export const Title = styled(Text).attrs({
	el: TextElements.Label,
	size: FontSizes.sm,
	weight: FontWeights.Medium,
	color: 'gray8',
})`
	margin-bottom: ${units(0.5)};
	display: block;
`;

export const Subtitle = styled(Text).attrs({
	el: TextElements.Div,
	size: FontSizes.sm,
	color: 'gray8',
})`
	margin-bottom: ${units(1)};
`;

export const FlexContainer = styled.div`
	display: flex;
	justify-content: flex-start;
`;

const buttonStyles = css`
	${fontWeight(FontWeights.Medium)}
	margin-right: ${units(1.5)};
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`;

export const UploadButton = styled(TextButton).attrs({ size: FontSizes.sm, textColor: 'blue3' })`
	${buttonStyles}
`;

export const DeleteButton = styled(TextButton).attrs({ size: FontSizes.sm, textColor: 'heartRed' })`
	${buttonStyles}
`;

export const RequestAccessButton = styled(TextButton).attrs({
	size: FontSizes.sm,
})`
	${buttonStyles}
`;

export const FormContainer = styled.div`
	position: relative;
	margin-left: ${units(2)};
	${lineHeight(FontSizes.sm)};
`;

export const UploadText = styled(Text).attrs({
	el: TextElements.P,
	weight: FontWeights.Medium,
	size: FontSizes.sm,
	color: 'gray5',
})`
	margin-block-start: 0;
	margin-block-end: 0;
`;

export const DefaultImg = styled.div<{
	$disabled: boolean;
	$background: keyof typeof CalmTheme.palette;
}>`
	background: ${p => palette(p.$background)};
	border-radius: 6px;
	height: ${imgHeight};
	width: ${imgWidth};
	display: flex;
	justify-content: center;
	align-items: center;
	${p => (!p.$disabled ? 'cursor: pointer' : '')};
	flex-shrink: 0;
`;

export const ImageContainer = styled.div<{
	$disabled: boolean;
	$darkMode?: boolean;
}>`
	height: ${imgHeight};
	width: ${imgWidth};
	position: relative;
	background: ${p => (p.$darkMode ? CalmTheme.palette.blackAlpha80 : '')};
	${p => (p.$darkMode ? 'border-radius: 6px' : '')};
	${p => (!p.$disabled ? 'cursor: pointer' : '')};
	flex-shrink: 0;
`;

export const LogoText = styled(Text).attrs({
	el: TextElements.Span,
	weight: FontWeights.Medium,
	size: FontSizes.sm,
})``;

export const SubscribeLink = styled.a`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Demi)};
	color: ${palette('gray8')};
	margin-bottom: ${units(1)};
	text-decoration: underline;
`;
