import styled, { css } from 'styled-components';
import { palette } from 'styled-tools';

import { Loader, fontWeight, FontWeights } from '@calm-web/design-system';

import WarningIcon from 'icons/warning-filled.svg';

export const Label = styled.label`
	${fontWeight(FontWeights.Medium)};
`;

export const LineItem = styled.div`
	display: flex;
	justify-content: space-between;

	> :first-child {
		flex-grow: 1;
	}

	> * {
		min-width: 80px;
	}
`;

export const ComparisonTable = styled.div`
	border: 1px solid ${palette('gray2')};
	border-radius: 8px;
	width: 100%;
	padding: 0.44rem;
	${fontWeight(FontWeights.Medium)};

	${LineItem} {
		margin-bottom: 0.5rem;

		&:last-child {
			margin-bottom: 0;
			border-top: 1px solid ${palette('gray2')};
			padding-top: 0.44rem;
		}
	}
`;

export const InlineLoader = styled(Loader)`
	width: 100px;
	height: auto;
`;

export const PriceLoadingContainer = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Light = styled.div`
	color: ${palette('gray4')};
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
`;

export const IconCss = css`
	margin-right: 8px;
	width: 16px;
	height: auto;
`;

export const Warning = styled(WarningIcon)`
	${IconCss};

	path {
		fill: ${palette('gold')};
	}
`;
