import { ReactElement, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Loader } from '@calm-web/design-system';

import CellTitle from '@/components/ui/CellTitle';
import { useShouldShowUnifiedBrandingUpload } from '@/hooks/api/useUnifiedBrandingUpload';
import { usePermissions } from '@/hooks/auth/usePermissions';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { Partner, PartnerCategoryType } from '@/types/store/reducers';

import LogoUploader from './LogoUploader';
import UnifiedLogoUploader from './LogoUploader/UnifiedLogoUploader';
import logoUploadMessages from './LogoUploader/messages';
import { Subtitle } from './LogoUploader/styles';
import messages from './messages';
import { Anchor, ContainerCard } from './styles';

function PartnerBranding({
	formProps,
	partner,
}: {
	formProps: EditPartnerFormProps;
	partner: Partner;
}): ReactElement {
	const { formatMessage } = useIntl();
	const [hasValidPermissions, actions] = usePermissions();
	const requiredPermissions = [actions.UPDATE];
	const { hash } = useLocation();
	const ref = useRef<null | HTMLDivElement>(null);
	const { data: shouldShowUnifiedBrandingUpload, loading } = useShouldShowUnifiedBrandingUpload();

	const canUploadDtcLogo = hasValidPermissions('dtc_logo_url', requiredPermissions);
	const canUploadOrRequestAccess =
		canUploadDtcLogo || hasValidPermissions('request_logo_access', [actions.CREATE]);
	const isDTCLeadGenPartnership = formProps.model.category === PartnerCategoryType.D2C_LEAD_GEN_PARTNERSHIP;
	const showDtcUploader =
		!isDTCLeadGenPartnership && (formProps.model.dtcLogoUrl || canUploadOrRequestAccess);

	useEffect(() => {
		if (hash === '#upload-logo') {
			ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [hash, ref]);

	if (loading) {
		return <Loader color="gray1" />;
	}

	return (
		<ContainerCard>
			<Anchor ref={ref} />
			<CellTitle>{formatMessage(messages.brandingCardTitle)}</CellTitle>
			{shouldShowUnifiedBrandingUpload ? (
				<>
					<Subtitle>{formatMessage(logoUploadMessages.customBrandingSubtitle)}</Subtitle>
					<UnifiedLogoUploader
						formProps={formProps}
						disabled={!hasValidPermissions('logo_url', requiredPermissions)}
						fileFieldName="uploadedLogo"
						urlFieldName="logoUrl"
						onDelete={() => {
							formProps.setProperty('dtcLogoUrl', '');
							formProps.setProperty('uploadedDtcLogo', []);
						}}
						analyticsEvent="Partner : Logo Upload : Selected"
					/>
				</>
			) : (
				<>
					<LogoUploader
						formProps={formProps}
						disabled={!hasValidPermissions('logo_url', requiredPermissions)}
						partner={partner}
						fileFieldName="uploadedLogo"
						urlFieldName="logoUrl"
					/>
					{showDtcUploader ? (
						<LogoUploader
							formProps={formProps}
							disabled={!canUploadOrRequestAccess}
							asRequestAccess={!canUploadDtcLogo}
							partner={partner}
							fileFieldName="uploadedDtcLogo"
							urlFieldName="dtcLogoUrl"
						/>
					) : null}
				</>
			)}
		</ContainerCard>
	);
}

export default PartnerBranding;
