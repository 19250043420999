import { defineMessages } from 'react-intl';

export default defineMessages({
	cardName: {
		id: 'plan.cardName',
		defaultMessage: 'Card: {cardname}',
		description: 'Card name for the plan section',
	},
	cardDetails: {
		id: 'plan.cardDetails',
		defaultMessage: 'Card details: •••• •••• •••• {cardnumber}',
		description: 'Card details for the plan section',
	},
	cardExp: {
		id: 'plan.cardExp',
		defaultMessage: 'exp: {cardexp}',
		description: 'Card exp for the plan section',
	},
	cardCvc: {
		id: 'plan.cardCvc',
		defaultMessage: 'cvc: {cardcvc}',
		description: 'Card cvc for the plan section',
	},
	changeDetailsLink: {
		id: 'plan.changeDetailsLink',
		defaultMessage: 'Change payment details',
		description: 'Change details link for the plan section',
	},
});
