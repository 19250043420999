import { ReactElement } from 'react';

import { useDefinedPartner } from '@/hooks/api/usePartner';
import { isCalmHealthProductSKU } from '@/utils/SkuUtils';

import B2BReporting from './B2BReporting';
import CHReporting from './CHReporting';

export default function Reporting(): ReactElement {
	const partner = useDefinedPartner();

	return isCalmHealthProductSKU(partner.product_sku) ? (
		<CHReporting partner={partner} />
	) : (
		<B2BReporting partner={partner} />
	);
}
