import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { Invoice } from '@/hooks/api/useInvoice';
import { currencyStringFromCents } from '@/utils/helpers';

import messages from './messages';
import { InlineLoader, Light, LineItem, PriceLoadingContainer, Row, Warning } from './styles';

interface Props {
	isLoading: boolean;
	hasError: boolean;
	previousCoveredLives: number;
	coveredLives: number;
	invoice?: Invoice;
	proratedInvoice?: Invoice;
}

export function ComparisonBody({
	isLoading,
	hasError,
	previousCoveredLives,
	coveredLives,
	invoice,
	proratedInvoice,
}: Props): ReactElement {
	const { formatMessage } = useIntl();
	const { data: flagForProrate } = useFeatureFlags('b2b-stripe-prorate-invoice-endpoint');
	const newProrateInvoiceLogicFlag = flagForProrate && flagForProrate['b2b-stripe-prorate-invoice-endpoint'];

	if (isLoading) {
		return (
			<PriceLoadingContainer>
				<InlineLoader color="gray5" />
				<div>{formatMessage(messages.priceLoadingText)}</div>
			</PriceLoadingContainer>
		);
	}

	if (hasError || !invoice || !proratedInvoice) {
		return (
			<PriceLoadingContainer>
				<Row>
					<Warning />
					{formatMessage(messages.priceLoadingError)}
				</Row>
			</PriceLoadingContainer>
		);
	}

	const { totalCost: previousTotalCost, pricePerCoveredLife: previousPricePerCoveredLife } = invoice;

	const { totalCost, pricePerCoveredLife, prorationAmount } = proratedInvoice;

	if (newProrateInvoiceLogicFlag) {
		return (
			<>
				<LineItem>
					<div />
					<div>{formatMessage(messages.nowLabel)}</div>
				</LineItem>
				<LineItem>
					<div>{formatMessage(messages.numberOfCoveredLivesLabel)}</div>
					<div>{coveredLives || '-'}</div>
				</LineItem>
				<LineItem>
					<div>{formatMessage(messages.costPerCoveredLifeRowLabel)}</div>
					<div>{pricePerCoveredLife ? currencyStringFromCents(pricePerCoveredLife) : '-'}</div>
				</LineItem>
				<LineItem>
					<div>{formatMessage(messages.billRowLabel)}</div>
					<div>{totalCost ? currencyStringFromCents(totalCost) : '-'}</div>
				</LineItem>
				<LineItem>
					<div>{formatMessage(messages.proratedBillLabel)}</div>
					<div data-testid="price-change-total-amount">
						{prorationAmount ? currencyStringFromCents(prorationAmount) : '-'}
					</div>
				</LineItem>
			</>
		);
	}
	return (
		<>
			<LineItem>
				<div />
				<div>{formatMessage(messages.wasLabel)}</div>
				<div>{formatMessage(messages.nowLabel)}</div>
			</LineItem>
			<LineItem>
				<div>{formatMessage(messages.numberOfCoveredLivesLabel)}</div>
				<Light>{previousCoveredLives}</Light>
				<div>{coveredLives || '-'}</div>
			</LineItem>
			<LineItem>
				<div>{formatMessage(messages.costPerCoveredLifeRowLabel)}</div>
				<Light>{currencyStringFromCents(previousPricePerCoveredLife)}</Light>
				<div>{pricePerCoveredLife ? currencyStringFromCents(pricePerCoveredLife) : '-'}</div>
			</LineItem>
			<LineItem>
				<div>{formatMessage(messages.billRowLabel)}</div>
				<Light>{currencyStringFromCents(previousTotalCost)}</Light>
				<div>{totalCost ? currencyStringFromCents(totalCost) : '-'}</div>
			</LineItem>
			<LineItem>
				<div>{formatMessage(messages.proratedBillLabel)}</div>
				<div />
				<div data-testid="price-change-total-amount">
					{prorationAmount ? currencyStringFromCents(prorationAmount) : '-'}
				</div>
			</LineItem>
		</>
	);
}
