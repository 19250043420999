import { ReactElement, SetStateAction, SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';

import { Button, SecondaryButton, Modal } from '@calm-web/design-system';

import StripePaymentElementForm from '@/components/pages/Teams/PaymentWithPaymentElement/PaymentForm/StripePaymentElementForm';
import { useUser } from '@/hooks/store';
import { calmLogger } from '@/utils/calmLogger';

import { BillingDetails } from '../types';
import messages from './messages';
import { LeftButtonWrapper } from './styles';

export interface Props {
	onModalDismiss: () => void;
	handleSubmit: () => Promise<void>;
	isUpdatingCard: boolean;
	billingDetails: BillingDetails;
	setBillingDetails: (details: SetStateAction<BillingDetails>) => void;
}

export default function ChangeCardModal({
	onModalDismiss,
	handleSubmit,
	isUpdatingCard,
}: Props): ReactElement {
	const { formatMessage } = useIntl();
	const { user } = useUser();

	function handleCardSubmit(e: SyntheticEvent): void {
		e.stopPropagation();
		e.preventDefault();
		handleSubmit().catch(err => calmLogger.error('Error in ChangeCardModal handleSubmit', {}, err));
	}

	return (
		<Modal
			isOpen
			closeModal={onModalDismiss}
			title={formatMessage(messages.updateTitle)}
			data-testid="change-card-modal"
			footer={
				<>
					<LeftButtonWrapper>
						<SecondaryButton onPress={onModalDismiss}>{formatMessage(messages.cancel)}</SecondaryButton>
					</LeftButtonWrapper>
					<Button
						backgroundColor="buttonGreen"
						isLoading={isUpdatingCard}
						data-testid="do-card-change"
						legacyOnClick={handleCardSubmit}
						type="submit"
					>
						{formatMessage(messages.updateDetails)}
					</Button>
				</>
			}
		>
			<StripePaymentElementForm fullName={user?.name} />
		</Modal>
	);
}
