import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { useSWRConfig } from 'swr';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { HealthConfig } from '@/types/health';
import { JSONValue } from '@/types/json';
import { isCalmError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { ApiResponse } from './types';

export default function useHealthConfig({ partnerId }: { partnerId?: string }): ApiResponse<HealthConfig> {
	const apiRequest = useApi();
	const dispatch = useDispatch();

	const endpoint = partnerId ? `b2b/partners/${partnerId}/health-config` : null;
	const { data, isLoading, error } = useSWR<HealthConfig>(endpoint, async endpoint => {
		try {
			const response = await apiRequest({ endpoint });
			return response.data.health_config as HealthConfig;
		} catch (err) {
			if (err?.status !== 404) {
				calmLogger.error('Error when fetching health config', {}, err);
				dispatch(
					setBannerMessage({
						message: `Error: ${
							(isCalmError(err) && err?.data?.error?.message) ||
							'There was a problem fetching the health config.'
						}`,
						flash: true,
						isError: true,
					}),
				);
			}
			throw err;
		}
	});

	return { data, loading: isLoading, error };
}

type UseSubmitExistingHealthConfigFormResponse = [
	submitExistingHealthConfigForm: (
		healthConfigData: Partial<HealthConfig>,
		partnerId: string,
	) => Promise<void>,
	state: ApiResponse<HealthConfig>,
];

export const useSubmitExistingHealthConfigForm = (): UseSubmitExistingHealthConfigFormResponse => {
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();
	const dispatch = useDispatch();
	const { mutate } = useSWRConfig();
	const [isSubmitting, setIsSubmitting] = useState(false);

	async function submitExistingHealthConfigForm(
		healthConfigData: Partial<HealthConfig>,
		partnerId: string,
	): Promise<void> {
		try {
			setIsSubmitting(true);

			const res = await apiRequest({
				endpoint: `b2b/partners/${partnerId}/health-config`,
				method: 'PATCH',
				body: healthConfigData,
			});
			const responseData = res?.data?.health_config ?? res?.data;
			if (responseData) {
				const updatedHealthConfigFields: { [field: string]: JSONValue } = {};
				Object.keys(responseData).forEach((field: string) => {
					if (healthConfigData && healthConfigData[field as keyof HealthConfig] !== responseData[field]) {
						updatedHealthConfigFields[field] = responseData[field];
					}
				});
				if (Object.keys(updatedHealthConfigFields).length) {
					logEvent('Health Config : Updated', { ...updatedHealthConfigFields });
				}
			}
			await mutate(`b2b/partners/${partnerId}/health-config`);
			dispatch(
				setBannerMessage({
					message: 'Successfully updated partner health config',
					flash: true,
					isError: false,
				}),
			);
		} catch (error) {
			dispatch(
				setBannerMessage({
					message: `An error has occurred updating partner health config!`,
					flash: true,
					isError: true,
				}),
			);
		} finally {
			setIsSubmitting(false);
		}
	}

	return [submitExistingHealthConfigForm, { data: undefined, loading: isSubmitting, error: undefined }];
};
