import { ReactElement, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IconButton, Loader, PrimaryButton } from '@calm-web/design-system';

import Table from '@/components/ui/Table';
import { useInvoices, useRefundInvoice } from '@/hooks/api/useInvoices';
import { useIsAdmin } from '@/hooks/auth/useAuth';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { Invoices } from '@/types/invoices';
import { Partner } from '@/types/store/reducers';
import { currencyStringFromDollars } from '@/utils/helpers';

import DownloadIcon from 'icons/download.svg';

import messages from './messages';
import {
	SectionContainer,
	Title,
	MinHeightCell,
	MobileWrapper,
	MobileDataColumn,
	MobileLabel,
	InvoiceDataWrapper,
} from './styles';

interface Props {
	partner: Partner;
}

interface Column {
	Header: string;
	accessor: keyof Invoices;
	width?: number | string;
	minWidth?: number;
}

function RefundCell({
	original,
	value,
	partnerId,
	refundedEvents,
}: {
	original: Invoices;
	value: string;
	partnerId: string;
	refundedEvents: Set<string>;
}): ReactElement {
	const [refundInvoiceEvent, { loading }] = useRefundInvoice(partnerId, value);
	const handleClick = (): Promise<void> => refundInvoiceEvent();

	const isStripeCredit = original.refunded === undefined;
	const refundId = original.payment_intent_id;
	const alreadyRefunded = refundedEvents?.has(refundId ?? '');

	if (isStripeCredit || alreadyRefunded) {
		return <MinHeightCell></MinHeightCell>;
	}

	return (
		<PrimaryButton size={'sm'} isLoading={loading} onPress={handleClick}>
			Refund
		</PrimaryButton>
	);
}

export default function InvoiceHistory({ partner }: Props): ReactElement {
	const { formatMessage } = useIntl();
	const [isMobile] = useIsMobile();

	const { data: invoiceHistory, loading: loading, error: error } = useInvoices(partner.id);

	const refundedEvents = useMemo(() => {
		if (!invoiceHistory) {
			return new Set<string>();
		}

		const invoiceHistoryRefundEvents = invoiceHistory?.filter(event => event.refunded === true);
		const refundPaymentIds = invoiceHistoryRefundEvents?.map(event => event.payment_intent_id);
		const filteredRefundPaymentIds = refundPaymentIds?.filter(id => id !== null);

		return new Set(filteredRefundPaymentIds);
	}, [invoiceHistory]);

	const isAdmin = useIsAdmin();

	function DateCell({ value }: { value: string }): ReactElement {
		const formattedDate = new Date(value).toLocaleString(undefined, {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			timeZone: 'UTC',
		});
		return <div data-testid="invoice-history-date">{formattedDate.toLowerCase()}</div>;
	}

	function AmountCell({ value }: { value: string }): ReactElement {
		return <div data-testid="invoice-history-amount">{currencyStringFromDollars(Number(value) / 100)}</div>;
	}

	function TypeCell({
		value,
		refunded,
	}: {
		value: string | boolean;
		refunded: boolean | undefined;
	}): ReactElement {
		let typeText = '';

		const combinedValue =
			value.toString() + (refunded ? '_refunded' : '' || refunded === undefined ? '_stripeCredit' : '');

		switch (combinedValue) {
			case 'subscription_create':
			case 'subscription_cycle':
				typeText = 'Annual bill';
				break;
			case 'subscription_create_refunded':
			case 'subscription_cycle_refunded':
				typeText = 'Annual bill (Refunded)';
				break;
			case 'subscription_update':
				typeText = 'Pro-rated charge';
				break;
			case 'subscription_update_refunded':
				typeText = 'Pro-rated charge (Refunded)';
				break;
			case 'subscription_create_stripeCredit':
			case 'subscription_cycle_stripeCredit':
			case 'subscription_update_stripeCredit':
				typeText = 'Stripe credit';
				break;
			default:
				typeText = 'Billing event';
		}
		return <div data-testid="invoice-history-event-type">{typeText}</div>;
	}

	function Quantity({ value }: { value: string }): ReactElement {
		return <div data-testid="invoice-history-quantity">{value}</div>;
	}

	function InvoiceCell({ value }: { value: string }): ReactElement {
		return (
			<div data-testid="invoice-history-invoice-download">
				{value && <IconButton color="blue3" aria-label="Download invoice" Icon={DownloadIcon} href={value} />}
			</div>
		);
	}

	function RefundReceiptCell({ value }: { value: string | null }): ReactElement {
		if (!value) {
			return <MinHeightCell />;
		}
		return (
			<div data-testid="invoice-history-invoice">
				{value && (
					<IconButton color="blue3" aria-label="Download refund receipt" Icon={DownloadIcon} href={value} />
				)}
			</div>
		);
	}

	const invoiceColumns: Column[] = useMemo(() => {
		function typeCellInvoice({ row }: { row: { original: Invoices } }): ReactElement {
			const { refunded, event_type } = row.original;
			return <TypeCell value={event_type} refunded={refunded} />;
		}

		const cells = [
			{ Header: 'Date', accessor: 'created_at' as const, Cell: DateCell },
			{ Header: 'Type', accessor: 'event_type' as const, Cell: typeCellInvoice },
			{ Header: 'Amount', accessor: 'amount' as const, Cell: AmountCell },
			{ Header: 'Lives Covered', accessor: 'quantity' as const, Cell: Quantity },
			{ Header: 'Invoice', accessor: 'invoice_url' as const, Cell: InvoiceCell },
			{ Header: 'Refund Receipt', accessor: 'refund_receipt_url' as const, Cell: RefundReceiptCell },
		];

		if (isAdmin) {
			return [
				...cells,
				{
					Header: 'Refund',
					accessor: 'id' as const,
					Cell: ({
						row: { original },
						value,
					}: {
						row: {
							original: Invoices;
						};
						value: string;
					}) => (
						<RefundCell
							original={original}
							value={value}
							partnerId={partner.id}
							refundedEvents={refundedEvents}
						/>
					),
				},
			];
		}

		return cells;
	}, [isAdmin, partner.id, refundedEvents]);

	return (
		<SectionContainer>
			<Title>
				<>{formatMessage(messages.billingHistory)}</>
				{loading && <Loader color="gray1" />}
			</Title>
			{invoiceHistory && (
				<>
					{isMobile ? (
						<InvoiceDataWrapper>
							{invoiceHistory.map((item: Invoices): ReactElement => {
								const {
									id,
									created_at,
									event_type,
									amount,
									quantity,
									invoice_url,
									refunded,
									refund_receipt_url,
								} = item;
								return (
									<MobileWrapper key={id}>
										<MobileDataColumn>
											<section>
												<MobileLabel>Date</MobileLabel>
												<DateCell value={created_at} />
											</section>
											<section>
												<MobileLabel>Type</MobileLabel>
												<TypeCell value={event_type} refunded={refunded} />
											</section>
										</MobileDataColumn>
										<MobileDataColumn>
											<section>
												<MobileLabel>Amount</MobileLabel>
												<AmountCell value={`${amount}`} />
											</section>
											<section>
												<MobileLabel>Lives Covered</MobileLabel>
												<Quantity value={`${quantity}`} />
											</section>
										</MobileDataColumn>
										<MobileDataColumn>
											<section>
												<MobileLabel>Invoice</MobileLabel>
												{invoice_url && <InvoiceCell value={invoice_url} />}
											</section>
										</MobileDataColumn>
										<MobileDataColumn>
											<section>
												<MobileLabel>Refund Receipt</MobileLabel>
												{refund_receipt_url && <RefundReceiptCell value={refund_receipt_url ?? null} />}
											</section>
										</MobileDataColumn>
										{isAdmin && (
											<section>
												<RefundCell
													original={item}
													value={id}
													partnerId={partner.id}
													refundedEvents={refundedEvents}
												/>
											</section>
										)}
									</MobileWrapper>
								);
							})}
						</InvoiceDataWrapper>
					) : (
						<>
							{
								<Table
									columns={invoiceColumns}
									data={invoiceHistory || []}
									fetchData={(): void => undefined}
									loading={loading}
									pageCount={1}
									error={error?.message}
									dataTestId="invoice-history-table"
									alignHeadingsToText
									cellVerticalAlign="middle"
								/>
							}
						</>
					)}
				</>
			)}
		</SectionContainer>
	);
}
