import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useOptionalPartner } from '@/hooks/api/usePartner';
import { useOptionalSaml } from '@/hooks/api/useSaml';
import { usePermissions } from '@/hooks/auth';
import { setSitewideBanner } from '@/store/actions';
import { IntegrationType, SitewideBannerCodes } from '@/types/store/reducers';
import { hasIncompleteSsoSetup } from '@/utils/SsoUtils';

export const useSitewideBannerDefaults = ({ partnerId }: { partnerId?: string }): void => {
	const dispatch = useDispatch();

	// Fetch and compute any data needed to determine the banner & info to show
	const { data: partner } = useOptionalPartner(partnerId) ?? {
		data: undefined,
	};
	const [hasValidPermissions, actions] = usePermissions();
	const canEditSsoConfig = hasValidPermissions('sso_config', [actions.UPDATE]);
	const { data: samlConfig } = useOptionalSaml(
		// don't try to fetch it if the partner isn't SSO or if we can't edit it
		canEditSsoConfig && partner?.integration_type === IntegrationType.SSO ? partnerId : undefined,
	) ?? {
		data: undefined,
	};
	const incompleteSsoSetup = hasIncompleteSsoSetup(partner, samlConfig);
	const { pathname } = useLocation();
	const pageName = useMemo(
		() => pathname.replace('/', '').replace(`${partnerId}/`, ''),
		[pathname, partnerId],
	);

	useEffect(() => {
		if (incompleteSsoSetup && pageName !== 'account/sso') {
			dispatch(
				setSitewideBanner({
					bannerCode: SitewideBannerCodes.SSO_CONFIG_INCOMPLETE,
					bannerInfo: null,
				}),
			);
			return;
		}
		dispatch(setSitewideBanner({ bannerCode: undefined, bannerInfo: null }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partner, samlConfig, pageName]);
};
